import React, {
  FocusEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { useUniqueId } from "../../../util/hooks";
import PLabel from "./PLabel";
import { classNames } from "../../../util/strings";
import PErrors from "./PErrors";
import PCharacterCount from "./PCharacterCount";

type PTextAreaProps = React.ComponentPropsWithoutRef<"textarea"> & {
  label?: string;
  errors?: string[];
  resize?: boolean;
  characterLimit?: number;
};

const PTextAreaComponent = forwardRef<HTMLTextAreaElement, PTextAreaProps>(
  (
    { label, resize, required, errors, value, characterLimit, ...props },
    ref,
  ) => {
    const labelId = useUniqueId();
    const innerRef = useRef<HTMLTextAreaElement | null>(null);
    const [text, setText] = useState(value || "");
    const [initialHeight, setInitialHeight] = useState<number | null>(null);

    const onFocus = (e: FocusEvent<HTMLTextAreaElement>) => {
      e.target.select();
      if (props.onFocus) {
        props.onFocus(e);
      }
    };

    // Taken from
    // https://medium.com/@oherterich/creating-a-textarea-with-dynamic-height-using-react-and-typescript-5ed2d78d9848

    useEffect(() => {
      if (!resize) {
        return;
      }
      if (innerRef && innerRef.current) {
        let innerInitialHeight: number;
        if (initialHeight === null) {
          innerInitialHeight = innerRef.current.offsetHeight;
          setInitialHeight(innerInitialHeight);
        } else {
          innerInitialHeight = initialHeight;
        }
        // We need to reset the height momentarily to get the correct scrollHeight for the textarea
        innerRef.current.style.height = "0px";
        const { scrollHeight } = innerRef.current;

        if (scrollHeight > innerInitialHeight) {
          // We then set the height directly, outside the render loop
          // Trying to set this with state or a ref will produce an incorrect value.
          innerRef.current.style.height = `${scrollHeight}px`;
        } else {
          innerRef.current.style.height = `${innerInitialHeight}px`;
        }
      }
    }, [innerRef, value]);

    useEffect(() => {
      setText(value || "");
    }, [value]);

    const textLength = typeof text === "string" ? text.length : 0;
    const charactersRemaining = characterLimit
      ? characterLimit - textLength
      : 0;
    const isOverLimit = characterLimit && charactersRemaining < 0;
    const inError = Boolean((errors && errors.length > 0) || isOverLimit);

    return (
      <div className="w-full">
        {label ? (
          <PLabel
            label={label}
            htmlFor={labelId}
            required={required}
            error={inError}
          />
        ) : null}

        <div className="relative rounded-md shadow-sm">
          <textarea
            id={labelId}
            className={classNames(
              "block w-full border-0 px-3 py-1.5 ring-1 ring-inset h-36",
              "placeholder:text-p-black-lightest focus:ring-2 focus:ring-inset focus:ring-primary",
              "sm:text-sm sm:leading-6 rounded-lg",
              inError ? "ring-danger" : "ring-p-black-lightest",
              props.disabled ? "cursor-not-allowed" : null,
            )}
            ref={(node) => {
              innerRef.current = node;
              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                // eslint-disable-next-line no-param-reassign
                ref.current = node;
              }
            }}
            required={required}
            value={value}
            onFocus={onFocus}
            onChange={(event) => {
              setText(event.target.value);
              if (props.onChange) props.onChange(event);
            }}
            {...props}
          />
        </div>
        {(errors && errors.length > 0) || !!characterLimit ? (
          <div
            className={classNames(
              "flex flex-row items-start w-full gap-3",
              errors && errors.length > 0 ? "justify-between" : "justify-end",
            )}
          >
            <PErrors className="mt-2" errors={errors} />
            {characterLimit ? (
              <PCharacterCount
                maxLength={characterLimit}
                curLength={typeof text === "string" ? text.length : 0}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    );
  },
);

PTextAreaComponent.defaultProps = {
  label: undefined,
  errors: undefined,
  resize: true,
  characterLimit: undefined,
};

export default PTextAreaComponent;
