import React from "react";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import TakeAwayList from "./TakeAwayList";

type ReportKeyLearningCardProps = {
  learning: components["schemas"]["KeyLearning"];
  index: number;
};

const ReportKeyLearningCardComponent = (props: ReportKeyLearningCardProps) => {
  const { learning, index } = props;

  return (
    <PCard>
      <div className="flex flex-col gap-4">
        <PTitle
          className="whitespace-pre"
          title={`Key Learning ${index + 1} - ${learning.title}`}
        />
        <div className="flex flex-col">
          <div className="font-semibold mb-2">🔎 Key Insights</div>
          <TakeAwayList takeaways={learning.takeaways} />
        </div>
        <div className="flex flex-col">
          <div className="font-semibold">Summary</div>
          <div className="text-p-black-lighter">{learning.summary}</div>
        </div>
        {learning.quotes ? (
          <div className="flex flex-col">
            <div className="font-semibold mb-1">Quotes</div>
            <div className="flex flex-col gap-1">
              {learning.quotes.map((quote) => (
                <div className="p-2 rounded-lg bg-primary-lightest/10 italic text-sm">
                  "{quote.quote}"
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </PCard>
  );
};

export default ReportKeyLearningCardComponent;
