import React, { useEffect, useRef, useState } from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import QuizQuestionCard from "../QuizQuestionCard";
import { components } from "../../../../api/spec";
import QuizQuestionCounter from "./QuizQuestionCounter";

type QuizQuestionsScreenProps = {
  questions: components["schemas"]["ListQuizQuestionsResponseSerializerWrapper"];
  selectedQuestions?: number[] | null;
  onNextClicked: (selected: number[]) => void;
};

const QuizQuestionScreenComponent = (props: QuizQuestionsScreenProps) => {
  const {
    questions,
    selectedQuestions: selectedQuestionsInput,
    onNextClicked,
  } = props;

  const [selectedQuestions, setSelectedQuestions] = useState<number[]>(
    selectedQuestionsInput ?? [],
  );
  const centerColumnRef = useRef<HTMLDivElement>(null);
  const [statsPosition, setStatsPosition] = useState<number>(0);
  const [busy, _] = useBusyWatcher();

  const onQuestionSelected = (index: number) => {
    const newQuestions = [...selectedQuestions];
    if (newQuestions.includes(index)) {
      newQuestions.splice(newQuestions.indexOf(index), 1);
    } else {
      newQuestions.push(index);
    }
    setSelectedQuestions(newQuestions);
  };

  const canSubmit = () =>
    selectedQuestions.length >= questions.content.min_selection_count &&
    selectedQuestions.length <= questions.content.max_selection_count;

  useEffect(() => {
    const updatePosition = () => {
      if (centerColumnRef.current) {
        const columnRightEdge =
          centerColumnRef.current.getBoundingClientRect().right;
        setStatsPosition(columnRightEdge + 50);
      }
    };

    // Initial position
    updatePosition();

    // Update position on window resize
    window.addEventListener("resize", updatePosition);
    return () => window.removeEventListener("resize", updatePosition);
  }, []);

  return (
    <PFormScreen
      nextDisabled={busy || !canSubmit()}
      onNextClicked={() => onNextClicked(selectedQuestions)}
      title="Your Questions"
      tooltip={`Now you need to pick the questions you want to include in your quiz. We generated these questions specifically for you based on your core question, audience, and goal. You may choose between ${questions.content.min_selection_count} and ${questions.content.max_selection_count} questions to include in your quiz`}
    >
      <div ref={centerColumnRef} className="flex flex-col gap-3">
        {questions.content.questions.map((question, index) => (
          <QuizQuestionCard
            key={question.description}
            question={question}
            onSelected={() => onQuestionSelected(index)}
            selected={selectedQuestions.includes(index)}
            disabled={
              busy ||
              (selectedQuestions.length >=
                questions.content.max_selection_count &&
                !selectedQuestions.includes(index))
            }
            disabledTooltip="You've reached the maximum number of questions. Remove another question to add this one."
          />
        ))}
      </div>
      <div className="sm:hidden fixed bottom-14 left-0 w-full px-5 py-3 bg-p-white">
        <QuizQuestionCounter
          minQuestionCount={questions.content.min_selection_count}
          maxQuestionCount={questions.content.max_selection_count}
          curQuestionCount={selectedQuestions.length}
        />
      </div>
      {statsPosition !== 0 ? (
        <div
          className="fixed bottom-24 shadow shadow-p-black-lightest rounded-lg p-3 bg-p-white"
          style={{ left: `${statsPosition}px` }}
        >
          <QuizQuestionCounter
            minQuestionCount={questions.content.min_selection_count}
            maxQuestionCount={questions.content.max_selection_count}
            curQuestionCount={selectedQuestions.length}
          />
        </div>
      ) : null}
    </PFormScreen>
  );
};

QuizQuestionScreenComponent.defaultProps = {
  selectedQuestions: null,
};

export default QuizQuestionScreenComponent;
