import React from "react";
import { components } from "../../../../api/spec";
import PSkeleton from "../../../ui/PSkeleton";
import PTitle from "../../../ui/PTitle";
import PErrors from "../../../ui/input/PErrors";
import ReportHeaderCard from "./ReportHeaderCard";
import ReportKeyInsightsCard from "./ReportKeyInsightsCard";
import ReportAnalyticsCard from "./ReportAnalyticsCard";
import ReportKeyLearningCard from "./ReportKeyLearningCard";
import ReportBackgroundCard from "./ReportBackgroundCard";
import ReportExecutiveSummaryCard from "./ReportExecutiveSummaryCard";
import ReportNextStepsCard from "./ReportNextStepsCard";

type ViewReportScreenProps = {
  report:
    | components["schemas"]["GetQuizReportResponseSerializerWrapper"]
    | null;
  errors: string[];
  createQuizUrl: string;
  viewQuizUrl: string;
  quizGuid: string;
};

const ViewReportScreenComponent = (props: ViewReportScreenProps) => {
  const { report, errors, createQuizUrl, viewQuizUrl, quizGuid } = props;

  if (errors.length > 0) {
    return <PErrors errors={errors} />;
  }

  if (!report) {
    return (
      <div>
        <div className="px-4 sm:px-0">
          <PTitle title="loading..." />
        </div>
        <div className="flex flex-col gap-5">
          <PSkeleton />
          <PSkeleton />
          <PSkeleton />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mb-3">
        <a
          href={`${viewQuizUrl}?quiz=${quizGuid}`}
          className="text-p-black-lighter hover:underline font-semibold"
        >
          &lt;&lt; back to quiz details
        </a>
      </div>
      <div className="flex flex-col gap-6">
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <ReportHeaderCard createQuizUrl={createQuizUrl} report={report} />
        <ReportAnalyticsCard report={report} />
        <ReportKeyInsightsCard report={report} />
        <ReportBackgroundCard report={report} />
        <ReportExecutiveSummaryCard report={report} />
        {report.content.report.key_learnings.map((learning, index) => (
          <ReportKeyLearningCard
            learning={learning}
            index={index}
            key={learning.title}
          />
        ))}
        <ReportNextStepsCard report={report} />
      </div>
    </>
  );
};

export default ViewReportScreenComponent;
