import React, { useState } from "react";
import { components } from "../../../api/spec";
import PBadge from "../../ui/PBadge";
import PCheckbox from "../../ui/input/PCheckbox";
import { classNames } from "../../../util/strings";
import { useUniqueId } from "../../../util/hooks";
import PTooltip from "../../ui/PTooltip";

type QuizQuestionCardProps = {
  question: components["schemas"]["QuizQuestionSummary"];
  index?: number | null;
  onSelected?: (() => void) | null;
  selected?: boolean | null;
  disabled?: boolean;
  disabledTooltip?: string | null;
};

export const QuestionTitleFromType = (
  type: components["schemas"]["QuestionTypeEnum"],
): string => {
  switch (type) {
    case "multiple_choice":
      return "Multiple Choice";
    case "short_text":
      return "Short Text";
    case "opinion_scale":
      return "Scale 1-10";
    case "long_text":
      return "Long Text";
    default:
      throw new Error(`Unknown question type: ${type}`);
  }
};

const QuizQuestionCardComponent = (props: QuizQuestionCardProps) => {
  const {
    question,
    index,
    onSelected: onSelectedInput,
    selected: selectedInput,
    disabled,
    disabledTooltip,
  } = props;

  const tooltipId = useUniqueId();
  const [selected, setSelected] = useState<boolean>(selectedInput || false);

  const onSelectedChanged = () => {
    setSelected(!selected);
    if (onSelectedInput) {
      onSelectedInput();
    }
  };

  const showCheckBox =
    onSelectedInput !== null && onSelectedInput !== undefined;

  const getContent = () => {
    const baseContent = (
      <div className="grid grid-cols-12">
        <div className="col-span-2 sm:col-span-1 font-bold self-center sm:self-start">
          {index !== null && index !== undefined ? `${index + 1}.` : null}
          {showCheckBox ? (
            <PCheckbox
              onChange={onSelectedChanged}
              checked={selected}
              disabled={disabled}
            />
          ) : null}
        </div>
        <div className="col-span-10 sm:col-span-11 flex flex-col gap-3">
          <div className="flex flex-row gap-3 justify-between items-center">
            <div className="font-bold">{question.title}</div>
            <PBadge
              className="bg-secondary"
              content={QuestionTitleFromType(question.question_type)}
            />
          </div>
          <div>{question.description}</div>
          {question.choices ? (
            <ul className="list-disc list-inside">
              {question.choices.map((choice) => (
                <li key={choice}>{choice}</li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    );
    if (!showCheckBox) {
      return baseContent;
    }
    return (
      <button
        type="button"
        className={classNames(
          "text-left w-full",
          disabled ? "cursor-not-allowed" : "cursor-pointer",
        )}
        onClick={onSelectedChanged}
        disabled={disabled}
      >
        {baseContent}
      </button>
    );
  };
  return (
    <>
      <div
        className={classNames(
          "p-3 rounded-lg",
          showCheckBox && selected ? "bg-primary/20" : null,
          showCheckBox && !disabled ? "hover:bg-primary/10" : null,
          showCheckBox && disabled
            ? "bg-p-black-lightest/5 text-p-black-lighter/60"
            : null,
        )}
        data-tooltip-id={tooltipId}
        data-tooltip-content={
          disabled && disabledTooltip ? disabledTooltip : null
        }
      >
        {getContent()}
      </div>
      <PTooltip id={tooltipId} />
    </>
  );
};

QuizQuestionCardComponent.defaultProps = {
  index: null,
  onSelected: null,
  selected: null,
  disabled: false,
  disabledTooltip: null,
};

export default QuizQuestionCardComponent;
