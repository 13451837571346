import { components } from "../../../../api/spec";

// eslint-disable-next-line import/prefer-default-export
export const bgColorFromImportance = (
  importance: components["schemas"]["ImportanceEnum"],
): string => {
  switch (importance) {
    case "critical":
      return "bg-success text-p-black";
    case "high":
      return "bg-danger text-p-white";
    case "medium":
      return "bg-warning text-p-white";
    case "low":
      return "bg-primary text-p-white";
    default:
      throw new Error(`Unknown importance: ${importance}`);
  }
};
