import React, { useEffect, useState } from "react";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../../ui/layout/PNavbarScreen";
import { components } from "../../../../api/spec";
import { useBusyWatcher } from "../../../../util/hooks";
import { getQuizReport } from "../../../../api/reflection/reports";
import ViewReportScreen from "./ViewReportScreen";
import { backgroundGradient } from "../../../../util/style";

type ViewReportPageProps = PNavbarScreenProps & {
  quiz: string;
  createQuizUrl: string;
  viewQuizUrl: string;
};

const ViewReportPageComponent = (props: ViewReportPageProps) => {
  const { quiz, navbar, createQuizUrl, viewQuizUrl } = props;

  const [report, setReport] = useState<
    components["schemas"]["GetQuizReportResponseSerializerWrapper"] | null
  >(null);
  const [errors, setErrors] = useState<string[]>([]);
  const [_, busyWatcher] = useBusyWatcher();

  const fetchReport = async () => {
    const [newReport, newError] = await getQuizReport({ quiz }, [busyWatcher]);
    if (newError !== null) {
      setErrors([newError.message!]);
      return;
    }
    setReport(newReport);
  };

  useEffect(() => {
    fetchReport();
  }, []);

  return (
    <PNavbarScreen navbar={navbar} bgClassname={backgroundGradient}>
      <ViewReportScreen
        report={report}
        errors={errors}
        createQuizUrl={createQuizUrl}
        viewQuizUrl={viewQuizUrl}
        quizGuid={quiz}
      />
    </PNavbarScreen>
  );
};

export default ViewReportPageComponent;
