import React, { useEffect, useState } from "react";
import { components } from "../../../../api/spec";
import PBadge from "../../../ui/PBadge";
import { classNames } from "../../../../util/strings";
import { bgColorFromImportance } from "./common";

type TakeAwayListProps = {
  takeaways: components["schemas"]["SurveyTakeaway"][];
};

const TakeAwayListComponent = (props: TakeAwayListProps) => {
  const { takeaways: takeawaysInput } = props;

  const [takeaways, setTakeaways] = useState<
    components["schemas"]["SurveyTakeaway"][]
  >([]);

  const sortSurveyTakeaways = (
    toSort: components["schemas"]["SurveyTakeaway"][],
  ): components["schemas"]["SurveyTakeaway"][] => {
    const importanceOrder: Record<
      components["schemas"]["ImportanceEnum"],
      number
    > = {
      critical: 1,
      high: 2,
      medium: 3,
      low: 4,
    };

    return toSort.sort(
      (a, b) => importanceOrder[a.importance] - importanceOrder[b.importance],
    );
  };

  const parseContent = (
    content: components["schemas"]["ImportanceEnum"],
  ): string => {
    if (content === "critical") {
      return "essential";
    }
    return content;
  };

  useEffect(() => {
    setTakeaways(sortSurveyTakeaways(takeawaysInput));
  }, []);

  return (
    <ul className="list-disc ml-5 space-y-4">
      {takeaways.map((takeaway) => (
        <li key={takeaway.takeaway}>
          <div className="flex flex-row items-center gap-2">
            <PBadge
              content={parseContent(takeaway.importance)}
              className={classNames(
                "min-w-20 font-semibold",
                bgColorFromImportance(takeaway.importance),
              )}
            />
            <div className="text-p-black-lighter">{takeaway.takeaway}</div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default TakeAwayListComponent;
