import React, { useState } from "react";
import RecipientEntryRow, { Recipient } from "../RecipientEntryRow";
import PBaseDialog from "../../../ui/dialogs/PBaseDialog";
import PButton from "../../../ui/buttons/PButton";
import { useBusyWatcher } from "../../../../util/hooks";

type innerProps = {
  onAddClicked: (newRecipient: Recipient) => void;
  onCancel?: (() => void) | null;
};

const AddRecipientDialogComponent = (props: innerProps) => {
  const { onAddClicked, onCancel } = props;

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [recipient, setRecipient] = useState<Recipient | null>(null);
  const [busy, _] = useBusyWatcher();

  const cancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const addRecipient = () => {
    setIsOpen(false);
    onAddClicked(recipient!);
  };

  const canSubmit = (): boolean => recipient !== null;

  return (
    <PBaseDialog title="Add Quiz Recipient" show={isOpen} onClose={cancel}>
      <RecipientEntryRow
        onEntryUpdated={setRecipient}
        onEnterPressed={() => canSubmit() && addRecipient()}
        showTitle={false}
      />
      <div className="flex flex-row w-full gap-4">
        <PButton className="w-1/2" kind="secondary" onClick={() => cancel()}>
          cancel
        </PButton>
        <PButton
          className="w-1/2"
          kind="primary"
          disabled={busy || !canSubmit()}
          onClick={() => addRecipient()}
        >
          add recipient
        </PButton>
      </div>
    </PBaseDialog>
  );
};

AddRecipientDialogComponent.defaultProps = {
  onCancel: null,
};

export default AddRecipientDialogComponent;
