import React from "react";
import { ShareIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { components } from "../../../../api/spec";
import PCard from "../../../ui/PCard";
import PTitle from "../../../ui/PTitle";
import PButton from "../../../ui/buttons/PButton";

type ReportHeaderCardProps = {
  report: components["schemas"]["GetQuizReportResponseSerializerWrapper"];
  createQuizUrl: string;
};

const ReportHeaderCardComponent = (props: ReportHeaderCardProps) => {
  const { report, createQuizUrl } = props;

  return (
    <PCard>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col sm:flex-row justify-between gap-3">
          <div className="flex flex-col gap-3">
            <PTitle title={`📝 Report for "${report.content.title}"`} />
            <div className="text-p-black-lighter">
              {report.content.description}
            </div>
          </div>
          <div className="flex flex-col gap-3 mt-4 sm:mt-0">
            <PButton
              kind="primary"
              className="w-full sm:w-48"
              href={createQuizUrl}
              icon={<PlusIcon className="h-5 w-5" />}
            >
              new quiz
            </PButton>
            <PButton
              kind="primary"
              className="w-full sm:w-48"
              disabledTooltip="Not implemented yet!"
              icon={<ShareIcon className="h-5 w-5" />}
              disabled
            >
              share
            </PButton>
            {/* <PButton */}
            {/*  kind="primary" */}
            {/*  onClick={onShareClicked} */}
            {/*  className="min-w-32 h-8" */}
            {/* > */}
            {/*  support */}
            {/* </PButton> */}
          </div>
        </div>
      </div>
    </PCard>
  );
};

export default ReportHeaderCardComponent;
